import React from "react";
import CustomDiv from "../../Shared/CustomDiv";
import { Carousel } from "react-responsive-carousel";
import Text from "../../Shared/Text";
import { Divider } from "@mui/material";
import classNames from "classnames";
import CustomButton from "../../Shared/CustomButton";
import { sliderListFn } from "../../Services/SliderList";
import { useMutation } from "react-query";
import { useState } from "react";
import { useEffect } from "react";
import { testimonialFn } from "../../Services/Testimonial";
import { bannerFn } from "../../Services/Banner";
import { allFiltersFn } from "../../Services/AllFilters/index,";
import { useNavigate } from "react-router-dom";
import { API_URLS } from "../../Config/API_URLS";
import axiosInstance from "../../Config/axios";

const LandingPage = () => {
  const [open, setOpen] = useState(null);
  const [data, setData] = useState([]);
  const [testimonials, setTestimonials] = useState([]);
  const [banners, setBanners] = useState([]);
  const [spices, setSpices] = useState([]);
  const [data1, setData1] = useState([]);
  const handleActiveHover = (id) => {
    setOpen(id);
  };
  const handleCloseHover = () => {
    setOpen(null);
  };

  const { mutate } = useMutation(sliderListFn, {
    onSuccess: (response) => {
      setData(response.data.data);
    },
  });
  const { mutate: testimonial } = useMutation(testimonialFn, {
    onSuccess: (response) => {
      setTestimonials(response.data.data);
    },
  });

  const { mutate: banner } = useMutation(bannerFn, {
    onSuccess: (response) => {
      setBanners(response.data.data);
    },
  });
  useEffect(
    () => {
      mutate();
      testimonial();
      banner();
    },
    // eslint-disable-next-line
    []
  );
  const navigate = useNavigate();
  const { mutate: allFilters } = useMutation(allFiltersFn, {
    onSuccess: (response) => {
      setSpices(response.data.data.product_list);
    },
  });

  useEffect(
    () => {
      allFilters({
        category_id: 5,
        sub_category_id: "",
        brand_id: "",
        color_id: "",
        max_price: "",
        min_price: "",
        rating: "",
      });
    },
    // eslint-disable-next-line
    []
  );
  const Product = () => {
    const reqBody = {
      add_quantity: 1,
    };
    axiosInstance.post(API_URLS.productList, reqBody).then((response) => {
      setData1(response.data.data.product_list);
    });
  };

  useEffect(() => {
    Product();
  }, []);
  console.log(data1);
  return (
    <CustomDiv className="flex flex-col w-full">
      <Carousel
        autoPlay
        showStatus={false}
        showThumbs={false}
        className="shubhga-carousel"
      >
        {data?.map((slider) => {
          return (
            <div key={slider.id}>
              <img alt="" src={slider.image} className="relative lg:h-96" />
            </div>
          );
        })}
      </Carousel>

      <CustomDiv className="flex flex-col-reverse gap-10 justify-center items-center my-4 bg-[#F2F8F9] p-4 ">
        <div className="flex flex-col gap-2 lg:gap-5">
          <Divider textAlign="left" className="font-bold lg:text-xl"></Divider>
          <div className="grid grid-cols-2 gap-2 lg:gap-10">
            {banners?.map((banner) => {
              return (
                <div
                  className="flex gap-10 cursor-pointer"
                  onClick={() =>
                    navigate("/all-products", {
                      state: {
                        from: "category",
                        category: banner.category,
                      },
                    })
                  }
                >
                  <img
                    src={banner.image}
                    alt=""
                    className="rounded lg:w-[680px]"
                  />
                </div>
              );
            })}
          </div>
        </div>
      </CustomDiv>
      <CustomDiv className="px-[4%] lg:px-[8%] bg-[#F2F8F9]">
        <CustomDiv className="flex gap-4 my-5 py-1 w-full hide-scroll px-4 overflow-x-auto">
          {data1?.map((product) => {
            return (
              <CustomDiv
                className="rounded-md h-fit w-[250px] duration-200 shadow bg-white cursor-pointer "
                onClick={() =>
                  navigate(`/product/${product?.id}/${product?.variant_id}`)
                }
              >
                <span className="centerdiv">
                  <img src={product.image} alt="" className="h-52 relative" />
                </span>
                <CustomDiv className="flex flex-col justify-between p-4 space-y-2">
                  <CustomDiv className="space-y-2">
                    <Text className="text-lg class-name font-semibold">
                      {product.title}
                    </Text>
                  </CustomDiv>

                  <CustomDiv className="flex items-center gap-3">
                    <Text className="">₹{product.price}</Text>
                    {product.discount_percent !== "0.0" ? (
                      <Text className="font-bold text-xl">
                        ₹{product.actual_price}
                      </Text>
                    ) : null}
                    <Text className="text-green-600">
                      {product.discount_percent !== "0.0"
                        ? `(${product.discount_percent?.split(".0")})%`
                        : null}
                    </Text>
                  </CustomDiv>
                </CustomDiv>
              </CustomDiv>
            );
          })}
        </CustomDiv>
      </CustomDiv>
      <CustomDiv className="flex flex-col justify-center items-center gap-1 my-10">
        <Text className="text-center text-xl font-semibold">
          Discount On Every Single Item On Our Site.
        </Text>
        <Text className="text-center text-blue-800 lg:text-4xl text-xl font-bold">
          <span className="text-blue-400 px-2 !font-semibold">
            OMG! JUST LOOK AT THE
          </span>
          GREAT DEALS!
        </Text>
        <span className="p-2 my-3 border-4 border-[#D32F2F] border-dashed rounded-full mx-3">
          <Text className="p-3 font-semibold text-center rounded-full text-white lg:text-xl gradient">
            HOW DOES IT FEEL, WHEN YOU SEE GREAT DISCOUNT DEALS FOR EACH
            PRODUCT?
          </Text>
        </span>
      </CustomDiv>
      <CustomDiv className="flex lg:flex-row flex-col gap-5 justify-between items-center px-[12%] gradient py-10 mx-3">
        <span className="text-white text-center">
          <Text className="font-semibold lg:text-2xl text-xl">
            SAVE UP TO 30% TO 40% OFF
          </Text>
          <Text className="font-bold lg:text-3xl text-xl">
            OMG! JUST LOOK AT THE GREAT DEALS!
          </Text>
        </span>
        <CustomButton className="!bg-white !p-3 !px-8 !text-black !rounded-full">
          View More
        </CustomButton>
      </CustomDiv>
      <CustomDiv className="bg-[rgb(242,251,254)] whitespace-nowrap overflow-x-auto hide-scroll m-3 flex gap-5 px-[8%]">
        {[
          "10% OFF",
          "Only $49",
          "Under @150",
          "Save Money",
          "80% OFF",
          "Free Shipping",
          "$79 Cashback",
          "Extra 10% OFF",
          "On Sale",
          "Free Shipping",
          "$79 Cashback",
          "Extra 10% OFF",
          "On Sale",
        ].map((item) => {
          return (
            <span className="flex justify-center items-center h-20 hover:bg-blue-300 hover:text-white transition-all duration-200 ease-linear p-3 px-5">
              <Text className="text-xl font-bold">{item}</Text>
            </span>
          );
        })}
      </CustomDiv>
      <Carousel
        autoPlay
        infiniteLoop
        showThumbs={false}
        className="testimonial-carousel"
      >
        {testimonials?.map((testimonial) => {
          return (
            <CustomDiv className="flex flex-col h-[600px] lg:h-64 gap-2 lg:flex-row gradient justify-center items-center lg:px-[20%] px-[4%] py-3 mx-3">
              <img
                src={testimonial.image}
                alt=""
                style={{ border: "5px solid black" }}
                className="rounded-full !w-52 !p-2 !border-2 !border-white"
              />

              <CustomDiv className="flex flex-col text-white">
                <Text className="text-xl font-bold text-center">
                  {testimonial.name}
                </Text>
                <Text className="lg:pr-[10%] text-center">
                  {testimonial.message}
                </Text>
              </CustomDiv>
            </CustomDiv>
          );
        })}
      </Carousel>
      <CustomDiv className="flex overflow-x-auto hide-scroll m-3">
        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13].map((item) => {
          return (
            <CustomDiv className="lg:w-1/2 h-80" key={item}>
              <img
                src={`https://source.unsplash.com/random/900×700/?food/${item}`}
                alt=""
                className="relative w-[295px] h-[295px] cursor-pointer"
              />
              <span
                onMouseEnter={() => handleActiveHover(item)}
                onMouseLeave={() => handleCloseHover(item)}
                className="relative overflow-hidden flex cursor-pointer justify-center items-center bottom-[19rem] pb-[108px] p-24 bg-white bg-opacity-0 hover:bg-opacity-20"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100"
                  fill="currentColor"
                  class="bi bi-instagram"
                  viewBox="0 0 16 16"
                  className={classNames(
                    "text-[#00BAF2] transition-all duration-700",
                    open === item
                      ? "scale-100 rotate-0"
                      : "scale-0 rotate-[-270deg]"
                  )}
                >
                  <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                </svg>
              </span>
            </CustomDiv>
          );
        })}
      </CustomDiv>
    </CustomDiv>
  );
};

export default LandingPage;
