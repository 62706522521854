import axios from "axios";

const axiosInstance = axios.create({
  // baseURL: "https://apps.amakaindustries.com/",
  baseURL: "https://mstore.bhaaraterp.com/",
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("shubhgatoken");
    config.headers = {
      Token: token,
      Authorization: "7c2b8693d001c79d4b5ed6ebc387ad6b862989_mstore",
      "Store-Id": 4,
      ...config.headers,
    };
    return config;
  },
  (err) => Promise.reject(err)
);
export default axiosInstance;
