import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../../Shared/CustomButton";
import CustomDiv from "../../Shared/CustomDiv";
import Text from "../../Shared/Text";
import contactLogo from "../../Assets/Contact us.gif";
import axiosInstance from "../../Config/axios";
import { API_URLS } from "../../Config/API_URLS";

const ContactUs = () => {
  const [fname, setFname] = useState("");
  const [lname, setLname] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");

  const SubmitContact = (event) => {
    event.preventDefault();
    const reqbody = new FormData();
    reqbody.append("name", fname);
    reqbody.append("lastName", lname);
    reqbody.append("number", number);
    reqbody.append("email", email);
    reqbody.append("massage", msg);
    if (number.length > 10) {
      alert("Plese Enter Valid Number");
      return;
    }
    axiosInstance
      .post(API_URLS.Contect, reqbody)
      .then((response) => {
        alert(response.data.msg);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const handleInputChange = (e) => {
    const newValue = e.target.value;

    if (!/[^a-zA-Z]/.test(newValue)) {
      setLname(newValue);
    } else {
      setLname("");
    }
  };
  const handleInputChangea = (e) => {
    const newValue = e.target.value;

    if (!/[^a-zA-Z]/.test(newValue)) {
      setFname(newValue);
    } else {
      setFname("");
    }
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <>
      <CustomDiv className="flex w-full bg-gray-100 p-[2%] lg:px-[8%]">
        <CustomDiv className="flex lg:flex-row flex-col w-full shadow">
          <form
            onSubmit={SubmitContact}
            className="flex flex-col gap-5 items-center bg-white py-9 lg:w-1/2"
          >
            <Text className="text-4xl font-semibold my-10">Contact Us</Text>
            <span className="grid lg:grid-cols-2 gap-4 lg:w-2/3 w-11/12">
              <span className="w-full">
                <Text>First Name*</Text>
                <TextField
                  placeholder="Enter First Name"
                  color="primary"
                  size="small"
                  className="w-full"
                  type="text"
                  value={fname}
                  onChange={(e) => handleInputChangea(e)}
                />
              </span>
              <span className="w-full">
                <Text>Last Name*</Text>
                <TextField
                  placeholder="Enter Last Name"
                  color="primary"
                  size="small"
                  className="w-full"
                  type="text"
                  value={lname}
                  onChange={(e) => handleInputChange(e)}
                />
              </span>
            </span>
            <span className="lg:w-2/3 w-11/12">
              <Text>Email*</Text>
              <TextField
                placeholder="Enter Email"
                color="primary"
                size="small"
                className="w-full"
                type="email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </span>
            <span className="lg:w-2/3 w-11/12">
              <Text>Phone Number*</Text>
              <TextField
                placeholder="Enter Phone Number"
                color="primary"
                size="small"
                className="w-full"
                type="number"
                onChange={(e) => setNumber(e.target.value)}
              />
            </span>
            <span className="lg:w-2/3 w-11/12">
              <Text>Message*</Text>
              <TextField
                placeholder="Enter Message"
                color="primary"
                size="small"
                multiline
                rows={4}
                className="w-full"
                onChange={(e) => setMsg(e.target.value)}
              />
            </span>
            <CustomButton type="submit" className="w-1/4">
              Submit
            </CustomButton>
          </form>

          <CustomDiv className="flex flex-col lg:w-1/2 lg:pt-40 lg:px-4 bg-white">
            <img
              src={
                "https://images.unsplash.com/photo-1484807352052-23338990c6c6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8M3x8Y29udGFjdCUyMGZvcm18ZW58MHx8MHx8fDA%3D&w=1000&q=80"
              }
              alt=""
              className="!rounded-l"
            />
          </CustomDiv>
        </CustomDiv>
      </CustomDiv>
    </>
  );
};

export default ContactUs;
