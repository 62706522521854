import { Fab, Paper } from "@mui/material";
import React from "react";
import Footer from "../Shared/Footer";
import Headers from "../Shared/Headers";
import { KeyboardArrowUp } from "@mui/icons-material";

const Layout = ({ theme, setTheme, component }) => {
  const handleTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <>
      <Headers theme={theme} setTheme={setTheme} />
      <Paper className="w-full h-full lg:pt-12 pt-10 !rounded-none !bg-white">
        {component}
      </Paper>
      <Fab
        color="inherit"
        onClick={handleTop}
        className="!fixed lg:!block !hidden !p-2 !z-50 bottom-10 right-10"
      >
        <KeyboardArrowUp fontSize="large" />
      </Fab>
      <Footer />
    </>
  );
};

export default Layout;
