import { SnackbarProvider } from "notistack";
import { useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import SignIn from "./Authentication/SignIn";
import SignUp from "./Authentication/SignUp";
import Layout from "./Layout";
import routes from "./Routes";
import { store } from "./store";
import { Provider } from "react-redux";
import Testing from "./Testing";

const App = () => {
  const [theme, setTheme] = useState(false);
  const queryClient = new QueryClient();
  // const themeMode = createTheme({
  //   palette: {
  //     mode: theme ? "dark" : "light",
  //   },
  // });
  return (
    // <ThemeProvider theme={themeMode}>
    <QueryClientProvider client={queryClient}>
      <SnackbarProvider preventDuplicate maxSnack={1}>
        <Provider store={store}>
          <BrowserRouter>
            <Routes>
              <Route path="/signup" element={<SignUp />} />
              <Route path="/test" element={<Testing />} />
              <Route path="/signin" element={<SignIn />} />
              {routes.map((route) => {
                return (
                  <Route
                    key={route.id}
                    path={route.path}
                    element={
                      <Layout
                        theme={theme}
                        setTheme={setTheme}
                        component={route.element}
                      />
                    }
                  />
                );
              })}
            </Routes>
          </BrowserRouter>
        </Provider>
      </SnackbarProvider>
    </QueryClientProvider>
    // </ThemeProvider>
  );
};

export default App;
