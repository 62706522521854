import * as React from "react";
import { Drawer } from "@mui/material";
import Text from "../../../../Shared/Text";
import CustomDiv from "../../../../Shared/CustomDiv";
import { CircularProgress } from "@mui/joy";
import CustomButton from "../../../../Shared/CustomButton";
import { API_URLS } from "../../../../Config/API_URLS";
import axiosInstance from "../../../../Config/axios";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { Visibility } from "@mui/icons-material";
import Pdf from "react-to-pdf";
import { useMutation } from "react-query";
import { createInvoiceFn } from "../../../../Services/CreateInvoice";
import logo from "../../../../Assets/amaka.png";

const InvoiceDetail = ({ OrderId }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [state, setState] = useState(false);
  const ref = React.createRef();

  const handleOpen = () => {
    createInvoice({ order_id: OrderId });
    setState(true);
  };

  const { enqueueSnackbar } = useSnackbar();

  const myOrderData = () => {
    setIsLoading(true);
    axiosInstance
      .post(API_URLS.invoiceDetail, { order_id: OrderId })
      .then((response) => {
        setData(response.data.data[0]);

        setIsLoading(false);
      })
      .catch((error) => {
        enqueueSnackbar("Something went wrong..!", { variant: "error" });
      });
  };
  const { mutate: createInvoice, isLoading: isLoadingInvoice } = useMutation(
    createInvoiceFn,
    {
      onSuccess: (response) => {
        myOrderData();
      },
    }
  );

  return (
    <>
      <Visibility
        onClick={handleOpen}
        className="cursor-pointer bg-[#306BDE] rounded text-white !p-2 !text-4xl"
      />
      <Drawer anchor="right" open={state} onClose={() => setState(false)}>
        {isLoading || isLoadingInvoice ? (
          <>
            <CustomDiv className="flex h-full lg:w-[790px] justify-center items-center">
              <CircularProgress size="lg" color="primary" />
            </CustomDiv>
          </>
        ) : (
          <div
            ref={ref}
            className="flex gap-5 flex-col lg:w-[790px] w-[390px] p-5 lg:p-16"
          >
            <Text className="font-semibold text-center p-1 w-full bg-black rounded text-white uppercase tracking-[0.7rem]">
              Invoice
            </Text>
            <span className="flex justify-between items-center my-2">
              <img src={logo} alt="" className="w-1/3" />
              <span className="flex flex-col gap-2">
                <Text className="font-semibold">
                  Invoice Number : #{data?.invoice_no}
                </Text>
                <Text className="font-semibold">
                  Invoice Date : {data?.date}
                </Text>
              </span>
            </span>
            <span className="flex gap-5 w-full">
              <span className="flex flex-col gap-3 w-full">
                <Text className="font-bold">Billing Address</Text>
                <span className="flex flex-col text-sm">
                  <Text className="font-semibold">
                    {data?.customer?.address}
                  </Text>
                  <Text className="font-semibold">
                    {data?.customer?.first_name} {data?.customer?.last_name}
                  </Text>
                  <Text>
                    {data?.customer?.address2}, {data?.customer?.pincode},
                    {data?.customer?.city}, {data?.customer?.state},
                    {data?.customer?.country},
                  </Text>
                </span>
              </span>
              <span className="flex flex-col gap-3 w-full">
                <Text className="font-bold">Shipping Address</Text>
                <span className="flex flex-col text-sm">
                  <Text className="font-semibold">
                    {/* {data?.user_address?.address_type} */}
                  </Text>
                  <Text className="font-semibold">
                    {data?.customer?.first_name} {data?.customer?.last_name}
                  </Text>
                  <Text>
                    {data?.customer?.address_2}, {data?.customer?.pincode},
                    {data?.customer?.city}, {data?.customer?.state},
                    {data?.customer?.country},
                  </Text>
                </span>
              </span>
            </span>
            <span className="border-y py-2 font-bold">Shipping Details</span>
            <span className="flex flex-col gap-1">
              <Text className="">Order Number : #{data?.order_id}</Text>
              <Text className="">Order Date : {data?.date}</Text>
            </span>
            <table className="w-full">
              <thead className="border-y border-gray-300 !rounded">
                <tr>
                  <th className="!p-2 !text-left">Items Name</th>
                  <th className="!p-2 !text-left">Quantity</th>
                  <th className="!p-2 !text-left">Price</th>
                </tr>
              </thead>
              <tbody className="!overflow-y-auto">
                {data?.items?.map((order) => {
                  return (
                    <tr className="border-b border-gray-300">
                      <td className="!p-2">{order.product_name}</td>
                      <td className="!p-2">{order.quantity}x</td>
                      <td className="!p-2">₹{order.price}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
            <CustomDiv className="flex flex-col items-end gap-2 w-full">
              <span className="flex justify-between w-full">
                <Text>Sub total:</Text>
                <Text>₹{data?.total_price}</Text>
              </span>
              <span className="flex justify-between w-full">
                <Text>Discount:</Text>
                <Text>-₹{data?.total_discount}</Text>
              </span>
              <span className="flex justify-between w-full">
                <Text>Total Tax Amount:</Text>
                <Text>+₹{data?.total_tax_amount}</Text>
              </span>
              <span className="flex justify-between w-full">
                <Text>Delivery Fee:</Text>
                <Text>
                  ₹
                  {data?.total_delivery_charge
                    ? data?.total_delivery_charge
                    : 0}
                </Text>
              </span>
              <span className="flex border-t border-gray-300 py-2 font-semibold w-full justify-between gap-4">
                <Text>Total Cost:</Text>
                <Text>
                  ₹
                  {data?.total_amount -
                    data?.coupon_discount_amount +
                    data?.total_tax_amount}
                </Text>
              </span>
            </CustomDiv>
            <CustomDiv className="flex text-sm flex-col">
              <Text>
                Description : Thank you for your order! Payment is expected
                within 31 days; please process this invoice within that time.
              </Text>
            </CustomDiv>
          </div>
        )}
        <CustomDiv className="flex lg:px-16 px-5 mb-5">
          <Pdf targetRef={ref} filename={`Amaka-${data?.invoice_no}.pdf`}>
            {({ toPdf }) => (
              <CustomButton className="!w-full" onClick={toPdf}>
                Generate Invoice
              </CustomButton>
            )}
          </Pdf>
        </CustomDiv>
      </Drawer>
    </>
  );
};
export default InvoiceDetail;
