import React, { useState, useEffect } from "react";
import { API_URLS } from "../../Config/API_URLS";
import axiosInstance from "../../Config/axios";
import * as DOMPurify from "dompurify";

const Term = () => {
  const [store, setStore] = useState([]);

  const Store = () => {
    axiosInstance.get(API_URLS.Store).then((response) => {
      setStore(response.data.data.store_data);
    });
  };

  useEffect(() => {
    Store();
  }, []);
  console.log(store);
  const clean = DOMPurify.sanitize(store?.contain);
  return (
    <>
      <div className="bg-gray-200">
        <p className="font-bold  text-4xl text-center py-5 ">
          Term and Condition
        </p>
        <div
          dangerouslySetInnerHTML={{ __html: store?.term_and_condition }}
          className="px-10  py-5"
        ></div>
      </div>
    </>
  );
};

export default Term;
